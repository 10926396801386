.flex{
    display: flex;
    margin: 0 30px;
    margin-top: 25px;
    gap: 10px;
}
.btn{
    background-color: rgb(75, 145, 244);
    color: white;
    padding: 8px 22px;
    font-weight: 500;
    border-radius: 50px;
    margin-top: 37px;
}
.btn1{
    background-color: rgb(75, 145, 244);
    color: white;
    padding: 8px 22px;
    font-weight: 500;
    border-radius: 50px;
    margin: 20px 5px 0 5px;
}
.main{
    text-align: center;
}
.center{
    text-align: center;
}