body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  overflow-x: hidden;
  overflow-y: hidden;
}

.teal {
  background: #419182;
  /* color: #fff; */
}

.red {
  background: #eb6f6f;
}
.ant-table-row {
  border-radius: 10px;
}

/* table:hover, */
/* thead:hover  */
tr:hover {
  background: #87dbce !important;
  color: #233633;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}

.gray {
  background: white;
}
.imgs {
  width: 80px !important;
  height: 80px !important;
}

.customDatePicker {
  border: 1.2px solid rgb(194, 202, 207);
  padding: 7px 11px;
  border-radius: 5px;
  width: 210px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}
.viewbtn {
  padding: 6px;
  background-color: rgb(238, 201, 38);
  color: white;
  margin: 0 5px;
  border-radius: 5px;
  font-size: 12px;
}
.viewbtn:hover {
  background-color: white;
  color: rgb(224, 157, 10);
  transition: 0.2s;
}
