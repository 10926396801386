.field {
  width: 100%;
  padding: 5px 10px;
  margin: 3px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.errorField {
  width: 100%;
  padding: 5px 10px;
  margin: 3px 0;
  display: inline-block;
  border: 1px solid red;
  border-radius: 4px;
  box-sizing: border-box;
}

.error {
  color: red;
  margin: 0px 12px 8px 0px !important;
}
