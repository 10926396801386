.socialIconDivDrag {
  display: flex;
  flex-direction: column;
  width: 92%;
  text-align: center;
  background: var(--whiteGrey);
  align-items: center;
  border-radius: 17px;
  box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 17%);
  aspect-ratio: 1;
  justify-content: space-evenly;
  padding: 8px 0;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}

.socialIconName {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  color: var(--blackWhite);
  margin-top: 3px;
  text-align: center;
}
.socialIcon {
  width: 25%;
}

.socialIconsMainDivDrag > div > div {
  width: 33.33%;
}
